import React  from 'react';
import { Container } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";

const Try8 = (props) =>(
    <section className="try-8">
        <Container>
            <GatsbyImage image={props.laptop} />
        </Container>
    </section>
);

export default Try8;
