import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

import sandwich from '../../images/try/sandwich.svg';
import calendar from '../../images/icons/calendar.svg';
import volume from '../../images/try/volume.svg';
import shoppingList from '../../images/try/shopping-list.svg';
import cloud from '../../images/try/cloud.svg';
import breakfast from '../../images/try/breakfast.svg';
import conversation from '../../images/try/conversation.svg';
import easter from '../../images/try/easter-egg.svg';
import help from '../../images/try/help.svg';
import measurement from '../../images/try/measurement.svg';

import {TRAINING_DAYS} from '../../constants'

const Try5 = () =>(
    <section className="try-5">
        <Container>
            <h2>Zobacz, co docenisz najbardziej!</h2>
            <Row>
                <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                    <img src={sandwich} alt="" className="icon" />
                    <p>Wymiana posiłków i produktów</p>
                </Col>
                <Col className="col-12 col-md-3 d-flex d-md-block text-center"> 
                    <img src={calendar} alt="" className="icon" />
                    <p>Ponad {TRAINING_DAYS} dni treningowych</p>
                </Col>
                <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                    <img src={volume} alt="" className="icon" />
                    <p>Różne poziomy trudności</p>
                </Col>
                <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                    <img src={shoppingList} alt="" className="icon" />
                    <p>Interaktywna lista zakupów</p>
                </Col>
                <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                    <img src={cloud} alt="" className="icon" />
                    <p>Dostęp do treningów bez internetu</p>
                </Col>
                <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                    <img src={breakfast} alt="" className="icon" />
                    <p>Kopiowanie posiłków na następny dzień</p>
                </Col>
                <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                    <img src={conversation} alt="" className="icon" />
                    <p>Opieka dietetyków online</p>
                </Col>
                <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                    <img src={easter} alt="" className="icon" />
                    <p>Okolicznościowe jadłospisy</p>
                </Col>
                <Col className="col-12 col-md-3 offset-md-3 d-flex d-md-block text-center">
                    <img src={help} alt="" className="icon" />
                    <p>Rady, wskazówki, motywacja</p>
                </Col>
                <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                    <img src={measurement} alt="" className="icon" />
                    <p>Dieta dopasowana do Twojego celu</p>
                </Col>
            </Row>
        </Container>
    </section>
);

export default Try5;
