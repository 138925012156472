import React  from 'react';
import { Container, Row, Col } from 'reactstrap';
import powerTriangle from '../../images/try/power-triangle.svg';

const Try1 = () =>(
    <section className="try-1">
        <Container>
            <Row>
                <Col xs={{size: 6, offset:3}} md={{size:3, offset: 0}} >
                    <img src={powerTriangle} alt="" className="img-fluid mb-5" />
                </Col>
                <Col md="9">
                    <h2>Trójkąt Mocy — jedzenie funkcjo­nalne, które poprawia jakość życia</h2>
                    <p>Życiowa moc bierze się z dobrego jedzenia, które zasila Twoje ciało, umysł i zmysły. Produkty w mojej diecie dobrane są tak, żeby dodać Ci sił w wybranych przez siebie sferach. Na które z nich postawisz?</p>
                </Col>
            </Row>
        </Container>
    </section>
);

export default Try1;
