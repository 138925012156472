import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import star from '../../images/try/star.svg';
import {USER_COUNT} from '../../constants';

class Try7 extends Component {
    render() {
        return (
            <section className="try-7">
                <Row className="align-items-stretch">
                    <Col md="4">
                        <Row>
                            <Col md="6">
                                <div className="panel transparent">
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <p>
                                        Ja jestem bardzo zadowolona! Osobiste sympatie
                                        lub jej brak do Pani Lewandowskiej nie mają tu
                                        nic do rzeczy. Liczy się sport…
                                    </p>
                                    <span className="sign">- Magdalena</span>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="panel">
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <p>
                                        Rewelacja! Proste przepyszne przepisy, zrzucanie
                                        zbędnych kilogramów to sama przyjemność
                                    </p>
                                    <span className="sign">- Barbara</span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    
                    <Col md="4">
                        <div class="panel">
                            <h2>{USER_COUNT}+</h2>
                            <p class="big">użytkowników <br/>Diet & Training by Ann</p>
                        </div>
                    </Col>

                    <Col md="4">
                        <Row>
                            <Col md="6">
                                <div className="panel">
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <p>
                                        Aplikacja prosta w użyciu. Bardzo pomocna lista
                                        zakupów (można iść do sklepu z telefonem i
                                        odhaczać po kolei co już mamy w koszyku)…
                                    </p>
                                    <span className="sign">- Szopeczka21</span>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="panel transparent">
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <img src={star} className="star" alt="" />
                                    <p>
                                        Proste i smaczne przepisy. Zdrowe i nie
                                        pracochłonne. Jestem bardzo zadowolona. Dziękuję
                                    </p>
                                    <span className="sign">- Ewelina</span>
                                </div>
                            </Col>
                        </Row>
    
                    </Col>
                </Row>
            </section>
        );
    }
}

export default Try7;
