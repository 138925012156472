import React  from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import scrollToElement from '../../helpers/scrollToElement';
import appIcon from "../../images/icons/signet.svg";

const Try4 = () =>(
    <section className="try-4">
        <Container>
            <Row>
                <Col xs={{size: 6, offset:3}} md={{size:3, offset: 0}} >
                    <img src={appIcon} className="img-fluid" alt="" />
                </Col>
                <Col md="9">
                    <h2 className="big">
                        7 dni = 0zł
                        <span className="info-blue">
                            <strong>POSPIESZ SIĘ</strong>: LICZBA KONT Z PRÓBNYM <br/>
                            DOSTĘPEM JEST OGRANICZONA
                        </span>
                    </h2>
                    <p>Nic nie ryzykujesz! Jeszcze zdążysz rozpocząć swój bezpłatny okres próbny. Sprawdź za darmo przez 7 dni, który plan treningowy będzie Twoim ulubionym i przekonaj się, że potrawy w diecie Diet & Training by Ann są nie tylko smaczne, ale i sycące!</p>
                    <Button
                        color="red"
                        onClick={() =>
                            scrollToElement(
                                document.getElementById('download')
                            )
                        }
                    >
                        Pobierz z okresem próbnym
                    </Button>
                </Col>
            </Row>
        </Container>
    </section>
);

export default Try4;
