import React  from 'react';
import { Container, Row, Col } from 'reactstrap';
import { APP_STORE_URL, GOOGLE_PLAY_URL } from '../../constants';
import app_store from '../../images/trainings/icons/app-store.svg';
import google_play from '../../images/trainings/icons/google-play.png';
import appIcon from "../../images/icons/signet.svg";

const Try11 = (props) =>(
    <section className="try-11" id="download">
        <Container>
            <Row>
                <Col xs={{size: 6, offset:3}} md={{size:3, offset: 0}} >
                    <img src={appIcon} className="img-fluid" alt="" />
                </Col>
                <Col md="9">
                    <span class="info-pink">OGRANICZONA LICZBA KONT Z PRÓBNYM DOSTĘPEM</span>
                    <h2>Pobierz Diet & Training by Ann z darmowym, 7-dniowym dostępem</h2>
                    <a
                        className="store-link"
                        href={APP_STORE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={app_store} alt="App Store" className="img-fluid" />
                    </a>
                    <a
                        className="store-link"
                        href={GOOGLE_PLAY_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={google_play} alt="Google Play" className="img-fluid" />
                    </a>                  
                </Col>
            </Row>
        </Container>
    </section>
);

export default Try11;
