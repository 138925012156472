import React  from 'react';
import { Container } from 'reactstrap';

const Try12 = () =>(
    <section className="try-12">
        <Container>
            <h2>Pytania i odpowiedzi</h2>

            <h3>Dlaczego muszę podać dane mojej karty płatniczej przy włączaniu okresu próbnego?</h3>
            <p>Apple i Google wymagają podania danych karty płatniczej przy rozpoczynaniu darmowego okresu testowego. Jest to od nas niezależne i nic nie możemy na to poradzić.</p>

            <h3>Dlaczego w cenniku widzę ceny?</h3>
            <p>Wyświetlone w aplikacji ceny to opłata za dostęp do wybranej usługi Diet & Training by Ann po zakończeniu darmowego okresu testowego.</p>

            <h3>Czy po 7 dniach za dostęp zostanie pobrana opłata?</h3>
            <p>Jeżeli w ciągu 7 dni od daty rozpoczęcia okresu testowego nie anulujesz subskrypcji, automatycznie zostanie pobrana opłata za dostęp do usługi zgodnie z opcją, która została wybrana podczas włączania okresu próbnego.</p>

            <h3>Usunąłem aplikację. Czy to wystarczy, by subskrypcja została anulowana?</h3>
            <p>Apple i Google nie anulują automatycznie subskrypcji po usunięciu aplikacji. Aby z niej zrezygnować, skorzystaj z poniższej instrukcji.</p>

            <h3>Nie pamiętam do kiedy obowiązuje mój okres testowy lub chcę zrezygnować z subskrypcji. Jak to zrobić?</h3>
            <p>Urządzenie z systemem Android: otwórz Sklep Google Play, kliknij Menu, a następnie Subskrypcje. Urządzenie z systemem iOS: otwórz Ustawienia, kliknij swoje imię i nazwisko, a potem opcję iTunes Store i App Store. Stuknij swój identyfikator Apple ID u góry ekranu, a następnie wybierz opcję Pokaż Apple ID. Przewiń do opcji Subskrypcje i stuknij ją. Stuknij subskrypcję, którą chcesz zarządzać.</p>

            <h3>Czy można wypróbować same treningów bez diety?</h3>
            <p>Tak. Możesz spróbować przez 7 dni bezpłatnie dowolnej opcji dostępu: Dieta, Trening lub Dieta i Trening</p>

            <h3>Czy mogę skorzystać z 7 darmowych dni mając już wykupiony dostęp na miesiąc?</h3>
            <p>Jeżeli posiadasz dostęp do diety, nie możesz skorzystać z darmowego dostępu do niej. Możesz jednak spróbować treningów lub diety i treningów. Jeśli posiadasz wykupiony dostęp do treningów, możesz spróbować darmowego dostępu do diety lub diety i treningów. W sytuacji, gdy posiadasz już dostęp do diety i treningów nie możesz skorzystać z naszego promocyjnego dostępu.</p>

            <h3>Czy okres próbny jest dla osób, które nigdy nie wykupiły dostępu do Diety?</h3>
            <p>Nie. Nawet jeśli korzystasz już z Diety, możesz skorzystać z darmowych 7 dni dostępu, by sprawdzić nasze Treningi.</p>

        </Container>
    </section>
);

export default Try12;
