import React  from 'react';
import { Container, Row, Col } from 'reactstrap';
import level1 from '../../images/try/level1.svg';

const Try2 = () =>(
    <section className="try-2">
        <Container>
            <Row>
                <Col md="9">
                    <h2>Diet by Ann = moje 4 diety</h2>
                    <p>Mam dla ciebie propozycję nie jednej, a czterech diet (w tym również wegetariańską). Wybierz taki plan żywienia, który najlepiej odpowiada Twoim aktualnym potrzebom. Nabierzesz ochoty na coś nowego? Za pomocą 2 kliknięć wybierz inny plan diety. Gotowe!</p>
                </Col>
                <Col xs={{size: 6, offset:3}} md={{size:3, offset: 0}} className="text-center">
                    <img src={level1} alt="" className="img-fluid" />
                    <p className="small"><strong>DOBRY START</strong><br/>
                    DROGA DO MISTRZOSTWA<br/>
                    WYZWANIE WOJOWNICZKI<br/>
                    WEGETARIAŃSKA</p>
                </Col>
            </Row>
        </Container>
    </section>
);

export default Try2;
