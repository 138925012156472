import React, { Component }  from 'react';
import { Container, Row, Col } from 'reactstrap';
import runningShoes from '../../images/try/running-shoes.svg';

import {TRAINING_PROGRAMS, TRAINING_DAYS, DVD_COUNT_GREATER_THAN} from '../../constants';

// import Odometer from 'react-odometerjs';

class Try3 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            odometer1: 0,
            odometer2: 0,
            odometer3: 0,
            odometer4: 0,
        };
    }

    componentDidMount(){
        this.setState({
            odometer1: TRAINING_PROGRAMS,
            odometer2: TRAINING_DAYS,
            odometer3: DVD_COUNT_GREATER_THAN,
            odometer4: 3,
        });
    }

    render(){
        return(
            <section className="try-3">
                <Container>
                    <Row>
                        <Col xs={{size: 6, offset:3}} md={{size:3, offset: 0}} className="text-center">
                            <img src={runningShoes} alt="" className="img-fluid mb-5" />
                        </Col>
                        <Col md="9">
                            <h2>Na jaki trening masz dziś ochotę?</h2>
                            <p>Włącz aplikację i wybierz swój idealny trening. Teraz możesz ćwiczyć!</p>

                            <Row>
                                <Col xs="12" md="3" className="text-center">
                                    <span className="big-number">{this.state.odometer1}
                                        {/* <Odometer value={this.state.odometer1} /> */}
                                    </span>
                                    <p>programów treningowych</p>
                                </Col>
                                <Col xs="12" md="3" className="text-center">
                                    <span className="big-number">{this.state.odometer2}
                                        {/* <Odometer value={this.state.odometer2} /> */}
                                    </span>
                                    <p>unikalnych <br/>dni</p>
                                </Col>
                                <Col xs="12" md="3" className="text-center">
                                    <span className="big-number">&gt;{this.state.odometer3}
                                        {/* <Odometer value={this.state.odometer3} /> */}
                                    </span>
                                    <p>płyt DVD w&nbsp;jednej aplikacji</p>
                                </Col>
                                <Col xs="12" md="3" className="text-center">
                                    <span className="big-number">{this.state.odometer4}
                                        {/* <Odometer value={this.state.odometer4} /> */}
                                    </span>
                                    <p>poziomy trudności</p>
                                </Col>
                            </Row>
                        </Col>
                        
                    </Row>
                </Container>
            </section>
        );
    }
}

export default Try3;
