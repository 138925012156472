import React  from 'react';
import { Container, Button } from 'reactstrap';
import scrollToElement from '../../helpers/scrollToElement';
import { GatsbyImage } from "gatsby-plugin-image";

const Hero = (props) =>(
    <>
        <section className="hero">    
            <GatsbyImage image={props.hero} className="hero-image" />

            <Container>
                <h1>DIETA I&nbsp;TRENINGI</h1>
                <p class="sub-heading">tak, jak lubisz</p>
                <p class="sub-heading">Dopasuj je do siebie <br/>i osiągnij zamierzone cele!</p>
                <Button
                    color="red"
                    onClick={() =>
                        scrollToElement(
                            document.getElementById('download')
                        )
                    }
                >
                    Wypróbuj przez 7 dni!
                </Button>
            
                <GatsbyImage image={props.hero} className="d-block d-md-none" />
                
            </Container>
        </section>
        
        <section className="red-bar">
            <Container>
                <p>LICZBA KONT Z DARMOWYM 7-DNIOWYM OKRESEM PRÓBNYM JEST OGRANICZONA</p>
            </Container>
        </section>
    </>
);

export default Hero;
