import React  from 'react';
import { Container, Row, Col } from 'reactstrap';
import steps from '../../images/try/steps.svg';
import advantagesMobile from '../../images/newyear2020/advantages-mobile.png';
import advantages from '../../images/newyear2020/advantages.png';

const Try10 = () =>(
    <>
        <section className="try-10">
            <Container>
                <Row>
                    <Col xs={{size: 6, offset:3}} md={{size:3, offset: 0}} >
                        <img src={steps} alt="" className="img-fluid mb-5" />
                    </Col>
                    <Col md="9">
                        <h2>Jak rozpocząć okres próbny?</h2>
                        <ol className="list">
                            <li>Pobierz aplikację Diet & Training by Ann na swój telefon</li>
                            <li>Dopasuj aplikację do siebie</li>
                            <li>Wybierz opcję dostępu (Dieta, Trening lub Dieta i Trening), która Cię interesuje</li>
                            <li>Postępuj zgodnie z krótką instrukcją Google lub Apple wyświetloną na ekranie</li>
                            <li>GOTOWE! Przez 7 dni cieszysz się dostępem do aplikacji i nic za to nie płacisz!</li>
                        </ol>
                    </Col>
                </Row>
            </Container>
        </section>
        <section>
            <Container>
                <img src={advantagesMobile} className="img-fluid d-md-none" alt="" />
                <img src={advantages} className="img-fluid d-none d-md-block" alt="" />
            </Container>
        </section>
    </>
);

export default Try10;
