import React from "react";
import '../helpers/suppress-install-prompt';
import Seo from "../components/seo";
import Layout from "../components/Layout";
import Hero from "../components/Try/Hero";
import Try1 from "../components/Try/Try1";
import Try2 from "../components/Try/Try2";
import Try3 from "../components/Try/Try3";
import Try4 from "../components/Try/Try4";
import Try5 from "../components/Try/Try5";
import Try6 from "../components/Try/Try6";
import Try7 from "../components/Try/Try7";
import Try8 from "../components/Try/Try8";
import Try9 from "../components/Try/Try9";
import Try10 from "../components/Try/Try10";
import Try11 from "../components/Try/Try11";
import Try12 from "../components/Try/Try12";

import { graphql} from "gatsby";

const Page = ({ data }) => (
    <Layout page="try" showMenu={true}>
        <Seo />
        <Hero hero={data.hero.childImageSharp.gatsbyImageData}  />
        <Try1 />
        <Try2 />
        <Try3 />
        <Try4 />
        <Try5 />
        <Try6
            socialProof1={data.socialProof1.childImageSharp.gatsbyImageData}
            socialProof2={data.socialProof2.childImageSharp.gatsbyImageData}
            socialProof3={data.socialProof3.childImageSharp.gatsbyImageData}
            socialProof4={data.socialProof4.childImageSharp.gatsbyImageData}
            socialProof5={data.socialProof5.childImageSharp.gatsbyImageData}
            socialProof6={data.socialProof6.childImageSharp.gatsbyImageData}
            socialProof7={data.socialProof7.childImageSharp.gatsbyImageData}
            socialProof8={data.socialProof8.childImageSharp.gatsbyImageData}
            socialProof9={data.socialProof9.childImageSharp.gatsbyImageData}
            socialProof10={data.socialProof10.childImageSharp.gatsbyImageData}
            socialProof11={data.socialProof11.childImageSharp.gatsbyImageData}
            socialProof12={data.socialProof12.childImageSharp.gatsbyImageData}
        />
        <Try7 />
        <Try8 laptop={data.laptop.childImageSharp.gatsbyImageData} />
        <Try9 />
        <Try10 />
        <Try11 />
        <Try12 />
    </Layout>
);

export default Page;

export const query = graphql`
    query {
        hero: file(relativePath: { eq: "try/ania.jpg" }) {
            ...fluidImage960
        }

        socialProof1: file(relativePath: { eq: "try/social-proof/social-proof-1.jpg" }) {
            ...fluidImage480
        }

        socialProof2: file(relativePath: { eq: "try/social-proof/social-proof-2.jpg" }) {
            ...fluidImage480
        }

        socialProof3: file(relativePath: { eq: "exclusive-renewal/social-proof/social-proof-3.jpg" }) {
            ...fluidImage480
        }

        socialProof4: file(relativePath: { eq: "exclusive-renewal/social-proof/social-proof-4.jpg" }) {
            ...fluidImage480
        }

        socialProof5: file(relativePath: { eq: "try/social-proof/social-proof-5.jpg" }) {
            ...fluidImage480
        }

        socialProof6: file(relativePath: { eq: "try/social-proof/social-proof-6.jpg" }) {
            ...fluidImage480
        }

        socialProof7: file(relativePath: { eq: "exclusive-renewal/social-proof/social-proof-7.jpg" }) {
            ...fluidImage480
        }

        socialProof8: file(relativePath: { eq: "try/social-proof/social-proof-8.jpg" }) {
            ...fluidImage480
        }

        socialProof9: file(relativePath: { eq: "try/social-proof/social-proof-9.jpg" }) {
            ...fluidImage480
        }

        socialProof10: file(relativePath: { eq: "try/social-proof/social-proof-10.jpg" }) {
            ...fluidImage480
        }

        socialProof11: file(relativePath: { eq: "try/social-proof/social-proof-11.jpg" }) {
            ...fluidImage480
        }

        socialProof12: file(relativePath: { eq: "exclusive-renewal/social-proof/social-proof-12.jpg" }) {
            ...fluidImage480
        }

        laptop: file(relativePath: { eq: "lifetime/app.png" }) {
            ...fluidImage960
        }
    }
`;

