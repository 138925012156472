import React, { Component } from 'react';
import Slider from 'react-slick';
import { GatsbyImage } from "gatsby-plugin-image";

class Try6 extends Component {
    render() {
        const settings = {
            infinite: true,
            slidesToShow: 10,
            slidesToScroll: 1,
            dots: false,
            speed: 500,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        arrows: false,
                    },
                },
            ],
        };

        return (
            <section className="try-6">
                <Slider {...settings}>
                    <div class="item"><GatsbyImage image={this.props.socialProof1} /></div>
                    <div class="item"><GatsbyImage image={this.props.socialProof2} /></div>
                    <div class="item"><GatsbyImage image={this.props.socialProof3} /></div>
                    <div class="item"><GatsbyImage image={this.props.socialProof4} /></div>
                    <div class="item"><GatsbyImage image={this.props.socialProof5} /></div>
                    <div class="item"><GatsbyImage image={this.props.socialProof6} /></div>
                    <div class="item"><GatsbyImage image={this.props.socialProof7} /></div>
                    <div class="item"><GatsbyImage image={this.props.socialProof8} /></div>
                    <div class="item"><GatsbyImage image={this.props.socialProof9} /></div>
                    <div class="item"><GatsbyImage image={this.props.socialProof10} /></div>
                    <div class="item"><GatsbyImage image={this.props.socialProof11} /></div>
                    <div class="item"><GatsbyImage image={this.props.socialProof12} /></div>
                </Slider>
            </section>
        );
    }
}

export default Try6;
