import React  from 'react';
import { Container, Row, Col } from 'reactstrap';
import seven from '../../images/try/seven.svg';

const Try9 = () =>(
    <section className="try-9">
        <Container>
            <Row>
                <Col xs={{size: 6, offset:3}} md={{size:3, offset: 0}} >
                    <img src={seven} alt="" className="img-fluid mb-5" />
                </Col>
                <Col md="9">
                    <h2>Zacznij dzisiaj darmowy dostęp 7-dniowy</h2>
                    <p>W aplikacji Diet &amp; Training by Ann wybierz opcję dostępu, którą chcesz przetestować i korzystaj z niej przez 7 dni całkowicie za darmo!</p>
                    <p>Czeka 5 trenerów - Anna Lewandowska, Joanna Jędrzejczyk, Mariola Bojarska-Ferenc, Ewelina Godlewska, Jakub Głąb oraz plany treningowe o różnych stopniach trudności i intensywności, które dopasują się do każdego i praktycznie w każdym wieku – funkcjonalne, dla osób dojrzałych (jedyna aplikacja na świecie z treningami 50+), dla par, dla ciężarnych (1, 2, 3 trymestr), po porodzie (od 12-go tygodnia po porodzie), dla mężczyzn, do wykonania w domu i poza nim, ze sprzętem i bez. Dodatkowo specjalne programy z yogą i ćwiczeniami barre (połączenie baletu z fitnessem) oraz funkcja Balans -  wyjątkowe miejsce wypełnione nagraniami prosto ze świata przyrody oraz autorską, relaksacyjną muzyką.</p>
                </Col>
            </Row>
        </Container>
    </section>
);

export default Try9;
